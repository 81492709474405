import React from 'react';
import { graphql, navigate } from 'gatsby';
import Select from 'react-select';
import styled from 'styled-components';
import { between } from 'polished';

import Page from '../components/page/page';
import HeaderSection from '../components/header-section';
import HeaderFront from '../components/header-front';
import Navigation from '../components/navigation';
import HeaderHeading from '../components/header-heading';
import HeaderDescription from '../components/header-description';
import SEO from '../components/seo';
import PostList from '../components/post-list';
import Container from '../components/container';
import Thumbnail from '../components/thumbnail';

const StyledHeaderSection = styled(HeaderSection)`
	min-height: ${between('400px', '650px')};
`;

const Meta = styled.div`
	display: flex;
	margin-bottom: 23px;
`;

const Filters = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
	margin-right: auto;

	.field {
		label {
			font-size: 12px;
			margin-bottom: 5px;
			display: inline-block;
		}
	}
`;

const TotalCount = styled.div`
	font-size: 14px;
	color: ${props => props.theme.colors.warm_grey_500};
	align-self: center;
`;

const styledSelect = { menu: styles => ({ ...styles, zIndex: 230 }) };

class ResultsPage extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			towns: [],
			currentTown: null,
			selectedTown: { value: '', label: 'Cała Polska' },
			currentCategory: props.data.mysqlCategory,
			isTownsDropdownVisible: false,
		};

		props.data.allMysqlTown.edges.forEach(({ node }) => {
			const obj = { value: node.slug, label: node.label_nominative };
			if (props.data.mysqlTown && node.slug === props.data.mysqlTown.slug) {
				this.state.selectedTown = obj;
				this.state.currentTown = props.data.mysqlTown;
			}
			this.state.towns.push(obj);
		});

		this.hideTownsDropdown = this.hideTownsDropdown.bind(this);
		this.handleTownChange = this.handleTownChange.bind(this);
		this.handleTownInputChange = this.handleTownInputChange.bind(this);

		if (typeof window !== 'undefined' && window && window.localStorage) {
			window.localStorage.setItem('lastUserCategory', JSON.stringify({ value: this.state.currentCategory.slug, label: this.state.currentCategory.label_nominative }));
			if (this.state.currentTown) window.localStorage.setItem('lastUserTown', JSON.stringify(this.state.selectedTown));
		}
	};

	hideTownsDropdown() {
		this.setState({ isTownsDropdownVisible: false });
	};

	handleTownChange(selectedTown) {
		if (typeof window !== 'undefined' && window && window.localStorage) window.localStorage.setItem('lastUserTown', JSON.stringify(selectedTown));
		navigate(`/${this.props.data.mysqlCategory.slug}/${selectedTown.value}/`);
	};

	handleTownInputChange(query, { action }) {
		if (action === 'input-change') this.setState({ isTownsDropdownVisible: query.length > 1 });
	};

	render() {
		let title = this.state.currentCategory.label_nominative;
		if (this.props.data.mysqlTown) title += ` ${this.props.data.mysqlTown.label_in}`;

		let seoTitle = '';
		if (this.props.data.mysqlTown) seoTitle = `${this.props.data.mysqlTown.label_nominative} – `;
		seoTitle += this.state.currentCategory.label_nominative_plural;

		let seoDescription = this.props.data.mysqlCategory.seo_description;
		if (this.props.data.mysqlTown) seoDescription += ` Zobacz oferty ${this.props.data.mysqlTown.label_in_area}.`;

		return (
			<Page>
				<SEO title={seoTitle} description={seoDescription} canonical={this.props.pageContext.canonical} />
				<StyledHeaderSection hero={'/' + this.state.currentCategory.hero_image}>
					<HeaderFront>
						<Navigation />
						<Container>
							<HeaderHeading>{title}</HeaderHeading>
							<HeaderDescription>{this.state.currentCategory.description}</HeaderDescription>
						</Container>
					</HeaderFront>
				</StyledHeaderSection>

				<Container>
					<Meta>
						<Filters>
							<div className="field">
								<label htmlFor="towns">Miasto</label>
								<Select id="towns" options={this.state.towns} value={this.state.selectedTown} className="select" placeholder="Wpisz miasto..." noOptionsMessage={() => 'Nie znaleziono miasta...'} menuIsOpen={this.state.isTownsDropdownVisible} onChange={this.handleTownChange} onBlur={this.hideTownsDropdown} onInputChange={this.handleTownInputChange} styles={styledSelect} theme={theme => ({
									...theme,
									borderRadius: 0,
									zIndex: 30,
									colors: {
										...theme.colors,
										primary25: 'hsl(43, 13%, 90%)',
										primary50: 'hsl(43, 13%, 90%)',
										primary: 'hsl(41, 8%, 61%)',
									},
								})} components={
									{
										DropdownIndicator: () => null,
										IndicatorSeparator: () => null,
									}
								} />
							</div>
						</Filters>

						<TotalCount>
							Liczba wyników: <b>{this.props.pageContext.totalItems}</b>
						</TotalCount>
					</Meta>

					<PostList>
						{this.props.pageContext.posts.map(post => (
							<Thumbnail key={post.node.slug} post={post.node} />
						))}
					</PostList>
				</Container>
			</Page>
		);
	};
};

export default ResultsPage;

export const query = graphql`
	query ResultsPage($categoryID: Int!, $townID: Int!) {
		mysqlCategory(uid: {eq: $categoryID}) {
			slug
			label_nominative
			label_nominative_plural
			description
			hero_image
			seo_description
		}

		mysqlTown(uid: {eq: $townID}) {
			slug
			label_nominative
			label_in
			label_in_area
		}

		allMysqlTown {
			edges {
				node {
					slug
					label_nominative
				}
			}
		}
	}
`;
